import { LogoHorizontal, LogoToBackgroundDark } from '@config/base/icons';

import { BaseLayout } from '@layouts/types';

import {
  BannerContainer,
  ChildrenContainer,
  Container,
  Divider,
  Footer,
  Header,
  InfoBanner,
  LogoContainer,
  SmallText,
  StLink,
  UsefulLinks,
} from './styles';

export const AuthLayout: React.FC<BaseLayout> = ({ children }) => {
  return (
    <Container>
      <BannerContainer>
        <LogoContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <LogoToBackgroundDark />
            <p>Seja bem-vindo ao</p>
            <h1>V4h api</h1>
          </div>
        </LogoContainer>
        <Divider />
        <InfoBanner>
          <p>
            Esta é uma plataforma completa para gerenciar a implementação da API
            dentro do seu sistema.
          </p>
          <UsefulLinks>
            <span>Consulte também os</span>
            <span>
              <StLink>Termos de Serviço</StLink> e{' '}
              <StLink>Política de Privacidade</StLink>
            </span>
          </UsefulLinks>
        </InfoBanner>
      </BannerContainer>
      <ChildrenContainer>
        <Header>
          <LogoHorizontal />
        </Header>
        {children}
        <Footer>
          <SmallText
            href="https://docs.google.com/document/d/1yvJvTd-VrzTlmU3J1szGxdU5Yg10bAXG7N-NXhOi0fA"
            target="_blank"
            rel="noreferrer"
          >
            Versão 1.0.0
          </SmallText>
          <SmallText>©️ Wisecare. Todos os direitos reservados.</SmallText>
        </Footer>
      </ChildrenContainer>
    </Container>
  );
};
