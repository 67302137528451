import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from '@stores/auth';

import { CookieKey } from './types';

export { CookieKey } from './types';

const sanitizeDomain = () => {
  return document.location.hostname.replace(/^[^.]+\./g, ``);
};

export const getCookie = (name: string, cookies: string): string | null => {
  const cookie =
    cookies.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || null;
  return cookie;
};

export const setCookie = (name: string, value: string) => {
  const expires = new Date();
  // expires.setHours(expires.getHours() + 1);
  expires.setMonth(expires.getMonth() + 3);
  document.cookie = `${name}=${value}; domain=${sanitizeDomain()}; expires=${expires.toUTCString()}; path=/; secure;`;
};

export const removeCookie = (name: string) => {
  document.cookie = `${name}=; domain=${sanitizeDomain()}; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setCurrentUser } = useCurrentUser.getState();

  const logout = () => {
    // Clear cookies
    removeCookie(CookieKey.AuthToken);

    // Reset user state
    setCurrentUser(undefined);

    // Invalidate queries
    queryClient.invalidateQueries();

    // Redirect to login
    navigate('/login');
  };

  return logout;
};
