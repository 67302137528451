/* eslint-disable @typescript-eslint/no-unused-vars */
import { ColumnDef } from '@tanstack/react-table';
import { SortButton, Typography } from '@wisecare-tech/design-system-web';

import { InvoiceByDetailsOrgsBillingsResponse } from '@services/invoice/types';

import { formatCurrency } from '@utils/format/currency';
import { formatISOToDateTime } from '@utils/format/formatDate';

import { Information } from './styles';

export const columnsGeral: ColumnDef<InvoiceByDetailsOrgsBillingsResponse>[] = [
  {
    accessorKey: 'timestamp',
    header: ({ column }) => (
      <SortButton column={column}>Data e Hora</SortButton>
    ),
    cell: ({ row }) => {
      const value = row.original;

      return (
        <Information>
          <Typography variant="b4_14regular">{formatISOToDateTime(value?.timestamp)}</Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return <SortButton column={column}>Usuário</SortButton>;
    },
    cell: ({ row }) => {
      const value = row.original;

      return (
        <Typography variant="b4_14regular">{value?.user?.name}</Typography>
      );
    },
  },
  {
    accessorKey: 'unit',
    header: ({ column }) => <SortButton column={column}>Sigla</SortButton>,
    cell: ({ row }) => {
      const value = row.original;

      return (
        <Information>
          <Typography variant="b4_14regular">
            {value?.services?.unit}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'services.name',
    header: ({ column }) => <SortButton column={column}>Serviço</SortButton>,
    cell: ({ row }) => {
      const value = row.original;

      return (
        <Information>
          <Typography variant="b4_14regular">
            {value?.services?.name}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'services.total',
    header: ({ column }) => <SortButton column={column}>Consumo</SortButton>,
    cell: ({ row }) => {
      const value = row.original;

      return (
        <Information>
          <Typography variant="b4_14regular">
            {formatCurrency(value.services.amount, 'R$/h')}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'total',
    header: ({ column }) => (
      <SortButton column={column}>Valor (BRL)</SortButton>
    ),
    cell: ({ row }) => {
      const value = row.original;

      return (
        <Information>
          <Typography variant="b4_14regular">
            {value?.services?.amount}
          </Typography>
        </Information>
      );
    },
  },
];
