import { Route, Routes } from 'react-router-dom';

import Home from '@home/screens/Home';

import Login from '@login/screens/Login';

import App from '@src/App';
import { Billing } from '@src/modules/billing/screens';
import { Dashboard } from '@src/modules/dashboard/screens';
import { RequestLink } from '@src/modules/password/screens/requestLink';
import { ResetPassword } from '@src/modules/password/screens/resetPassword';

import { PrivateRoute } from './_PrivateRoute';
import { ROUTES } from './names';

const Router: React.FC = () => {

  return (
    <Routes>
      <Route path={ROUTES.PUBLIC.ROOT()} element={<App />}>
        <Route index element={<Login />} />

        <Route
          path={ROUTES.PRIVATE.ROOT()}
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.PUBLIC.RECOVERYPASSWORD()}
          element={<RequestLink />}
        />

        <Route
          path={ROUTES.PUBLIC.RECOVERYPASSWORD('reset')}
          element={<ResetPassword />}
        />

        <Route
          path={ROUTES.PRIVATE.DASHBOARD()}
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.PRIVATE.BILLING()}
          element={
            <PrivateRoute>
              <Billing />
            </PrivateRoute>
          }
        />
        
        <Route path="*" element={<Login />} />
      </Route>
    </Routes>
  );
};

export default Router;
