import styled from 'styled-components';

import { pxToRem } from '@utils/format/pixelToRem';

export const StFormLogin = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  gap: ${pxToRem('24px')};

  @media (min-width: 768px) {
    height: auto;
  }
`;

export const StTitleFormLogin = styled.h1`
  font-size: ${pxToRem('18px')};
  line-height: 2.1rem;
  font-weight: 600;
`;

export const InputsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${pxToRem('8px')};

  & .input + .input {
    margin-top: ${pxToRem('24px')};
  }
`;

export const LoginActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: ${pxToRem('16px')};
  margin-top: ${pxToRem('8px')};

  & a {
    font-size: ${pxToRem('14px')};
    line-height: ${pxToRem('16px')};
    font-weight: 500;
    color: #0565d9;
    text-decoration: none;
    vertical-align: middle;
  }
`;
