import styled from 'styled-components';

import { pxToRem } from '@utils/format/pixelToRem';

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${pxToRem('40px')};

  flex-wrap: wrap;

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
  }

  & > h3 {
    text-wrap: nowrap;
    font-size: ${pxToRem('16px')};
  }
`;

export const SelectContainer = styled.div<{ width?: string }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: ${pxToRem('8px')};

  @media (min-width: 1024px) {
    width: ${({ width }) => width ?? '100%'};
  }
`;

export const SelectLabel = styled.label`
  font-size: ${pxToRem('14px')};
  font-weight: 500;
  color: #222529;
`;
