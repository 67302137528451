import styled, { css } from 'styled-components';

import { pxToRem } from '@utils/format/pixelToRem';

const commonStyle = css`
  padding: 1rem 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 0.4rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FiltersContainer = styled.div`
  ${commonStyle}
  max-width: 47.7rem;
  border-right: 1px solid #dedede;
  flex-direction: column;
  gap: 0.8rem;
`;

export const Content = styled.div`
  ${commonStyle}
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InfoTitle = styled.h3`
  font-weight: 600;
  font-size: ${pxToRem('20px')};
  color: #444a51;
  margin-bottom: 0.8rem;
`;

export const InfoValue = styled.span`
  font-weight: 600;
  font-size: ${pxToRem('32px')};
  color: #005bc8;
  margin-bottom: 1rem;
`;

export const InfoPeriod = styled.span`
  font-size: ${pxToRem('14px')};
  color: #444a51;
  margin-bottom: 0.8rem;
`;

export const InfoValidity = styled(InfoPeriod)`
  margin-bottom: 0;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
