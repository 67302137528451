/* eslint-disable @typescript-eslint/no-unused-vars */
import { ColumnDef } from '@tanstack/react-table';
import { SortButton, Typography } from '@wisecare-tech/design-system-web';

import { Services } from '@services/invoice/types';

import { formatCurrency } from '@utils/format/currency';

import { Information } from './styles';

export const columnsByService: ColumnDef<Services>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => <SortButton column={column}>Serviço</SortButton>,
    cell: ({ row }) => {
      const name = row.getValue('name') as Services['name'];

      // const value = row.original;

      return (
        <Information>
          <Typography variant="b4_14regular">{name}</Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'unit',
    header: ({ column }) => {
      return <SortButton column={column}>Unitário</SortButton>;
    },
    cell: ({ row }) => {
      const unit = row.getValue('unit') as Services['unit'];

      return <Typography variant="b4_14regular">{unit}</Typography>;
    },
  },
  {
    accessorKey: 'amount',
    header: ({ column }) => <SortButton column={column}>Consumo</SortButton>,
    cell: ({ row }) => {
      // const amount = row.getValue('amount') as Services['amount'];

      const value = row.original;

      return (
        <Information>
          <Typography variant="b4_14regular">
            {formatCurrency(value.amount, 'R$/h')}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'total',
    header: ({ column }) => <SortButton column={column}>Valor</SortButton>,
    cell: ({ row }) => {
      const total = row.getValue('total') as Services['total'];

      return (
        <Information>
          <Typography variant="b4_14regular">{total}</Typography>
        </Information>
      );
    },
  },
];
