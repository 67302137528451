import styled, { css } from 'styled-components';

import { pxToRem } from '@utils/format/pixelToRem';

interface ContainerProps {
  width: string;
  horizontal?: boolean;
}

interface SelectContainerProps {
  height: string;
  focus: boolean;
  disabled: boolean | undefined;
  error: boolean;
  border: boolean;
  minimal?: boolean;
}

interface MessageProps {
  error: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => props.width};
  font-family: 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  display: flex;
  align-items: ${({ horizontal }) => (horizontal ? 'center' : 'flex-start')};
  justify-content: space-between;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  gap: ${({ horizontal }) => (horizontal ? '1rem' : '0')};
`;

export const SelectGroup = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  font-weight: lighter;
  padding-left: 16px;
  border: 1px solid #dfdfdf;
  background: #ffffff;
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #444a51;
  margin-bottom: 2px;
`;

export const SelectContainer = styled.span<SelectContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  height: ${(props) => props.height};
  border: 1px solid #b4b7b9;
  border-radius: 8px;
  border-color: ${({ focus }) => focus && `#00f`};
  background: #fff;
  width: 100%;

  & > .icon {
    position: absolute;
    right: 0;
    margin-right: 8px;
  }

  select {
    font-family: inherit;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    background-color: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #444a51;
    padding-left: 16px;
    z-index: 1;
    cursor: pointer;

    &.decorated {
      option:hover {
        background: #5f6871;
      }
    }

    option {
      :focus {
        background: red;
      }
    }

    ::placeholder {
      color: #bfc4ca;
    }

    :focus {
      #spanfocus {
        border-color: #00f;
      }
    }

    :after {
      background: white;
    }

    -moz-appearance: none;
    -webkit-appearance: none;
  }

  button {
    position: absolute;
    right: 0;
    width: 47px;
    height: calc(100% + 2px);
    border: none;
    color: ${(props) => (props.focus ? '#ffffff' : '#5F6871')};
    border-left: 1px solid;
    border-radius: 0px 3px 3px 0px;
    border-color: #bfc4ca;
    background-color: transparent;
    margin-right: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    position: absolute;
    right: 0;
    margin-right: 8px;
  }

  .icon {
    margin-right: 13px;
  }

  :hover {
    border-color: #00f;

    button {
      border-color: #00f;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #fafafb;
      :hover {
        border-color: #bfc4ca;

        button {
          border-color: #bfc4ca;
        }
      }

      button {
        z-index: 0;
      }
    `}

  ${({ minimal }) =>
    minimal &&
    css`
      background: transparent;
      border-color: transparent;
      z-index: 0;

      :hover {
        opacity: 0.8;
        border-color: transparent;

        button {
          border-color: transparent;
        }
      }

      button {
        border-color: transparent;
      }
    `}
`;

export const Message = styled.span<MessageProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #7b8591;

  ${({ error }) =>
    error &&
    css`
      color: #ea3e4f;
    `}
`;

export const SelectLabel = styled.label`
  font-size: ${pxToRem('14px')};
  font-weight: 500;
  color: #222529;
  width: 25%;
`;
