import styled from 'styled-components';

import { pxToRem } from '@utils/format/pixelToRem';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3.2rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: ${pxToRem('24px')};
  font-weight: 700;
  line-height: ${pxToRem('28px')};
  text-align: center;
  color: #222529;
`;

export const Details = styled.p`
  font-size: ${pxToRem('16px')};
  font-weight: 500;
  line-height: ${pxToRem('18px')};
  color: #656a6e;
  text-align: center;
  max-width: ${pxToRem('331px')};
  margin-top: ${pxToRem('8px')};
`;
