import styled from 'styled-components';

import { pxToRem } from '@utils/format/pixelToRem';

import { InputProps } from './types';

export const InputContainer = styled.div`
  width: 100%;
  gap: ${pxToRem('8px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StInputLabel = styled.label`
  font-size: ${pxToRem('14px')};
  font-weight: 500;
  line-height: ${pxToRem('16px')};
  color: #222529;
`;

const borderColorByStatus = (
  error: string | undefined
): Record<'border' | 'focus' | 'shadow', string> => {
  return {
    border: error ? '#DD0404' : '#b4b7b9',
    focus: error ? '#DD0404' : '#3A8AEA',
    shadow: error ? 'transparent' : '#3A8AEA',
  };
};

export const StInput = styled.input<InputProps>`
  width: 100%;
  height: ${pxToRem('40px')};

  padding: ${pxToRem('12px')} ${pxToRem('16px')};

  border: 1px solid ${({ error }) => borderColorByStatus(error).border};
  border-radius: 0.6rem;

  background-color: #fdfdfd;

  font-size: ${pxToRem('14px')};
  font-weight: 400;
  line-height: 1.6rem;

  transition: border-color 0.2s ease;

  &:placeholder {
    color: #656a6e;
    font-size: ${pxToRem('14px')};
    font-weight: 400;
    line-height: 1.6rem;
  }

  &:disabled {
    background-color: #f2f2f2;

    &:placeholder {
      color: #818385;
    }
  }

  &:focus:not(:disabled) {
    outline: none;
    border-width: 2px;
    padding: ${pxToRem('11px')} ${pxToRem('15px')};
    border-color: ${({ error }) => borderColorByStatus(error).focus};
    box-shadow: 0 0 1.2rem 0 ${({ error }) => borderColorByStatus(error).shadow};
  }

  &:active:not(:disabled) {
    outline: none;
    border-color: ${({ error }) => borderColorByStatus(error).focus};
    box-shadow: 0 0 0 0.2rem ${({ error }) => borderColorByStatus(error).shadow};
  }
`;

export const StInputMessageError = styled.span`
  color: #dd0404;
  font-weight: 500;
  font-size: ${pxToRem('14px')};
`;
