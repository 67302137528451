import styled from 'styled-components';

import { pxToRem } from '@utils/format/pixelToRem';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const getColorByStrength = (strength: number) => {
  if (strength < 30) {
    return '#FF0000';
  }
  if (strength < 60) {
    return '#FFA500';
  }
  return '#008000';
};

export const Strength = styled.progress`
  width: 100%;
  height: 8px;
  position: relative;
  border-radius: 100px;

  &::-webkit-progress-bar {
    border-radius: ${pxToRem('10px')};
    background-color: #d9d9d9;
  }

  &::-webkit-progress-value {
    border-radius: ${pxToRem('10px')};
    background-color: ${({ value }) => getColorByStrength(Number(value))};
  }

  &::-moz-progress-bar {
    border-radius: ${pxToRem('10px')};
    background-color: ${({ value }) => getColorByStrength(Number(value))};
  }
`;

export const Title = styled.h1`
  font-size: ${pxToRem('16px')};
  font-weight: 400;
  color: #222529;
  margin-top: ${pxToRem('10px')};
`;
