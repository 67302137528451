/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactComponent as Empty } from '@config/base/icons/emptyStateTable.svg';

import { EmptyStateText } from './styles';

export const EmptyTable: React.FC = () => {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        flexDirection: 'column',
        marginBottom: '40px',
      }}
    >
      <Empty />
      <EmptyStateText>
        Parece que ainda não temos informações de faturamento para mostrar aqui.
        <p>Vamos aguardar até que os dados estejam disponíveis.</p>
      </EmptyStateText>
    </div>
  );
};
