/* eslint-disable import/order */
// import { Header } from "@components/Header";
import { Header } from '@wisecare-tech/design-system-web';

import { SubHeader } from '@components/SubHeader';

import { BaseLayout } from '@layouts/types';

import { useLogout } from '@utils/cookies';

import { Body, Container } from './styles';
import { useCurrentUser } from '@stores/auth';

export const MainLayout: React.FC<BaseLayout> = ({ children }) => {
  const logout = useLogout();
  const { current } = useCurrentUser();

  return (
    <Container>
      <div style={{ width: '100%' }}>
        <Header
          onLogout={logout}
          options={[
            { title: 'Faturamento', href: '/' },
            { title: 'Billing', href: '/billing' },
          ]}
          user={{ name: current?.user.email ?? '', role: current?.user.login }}
        />
        <SubHeader />
      </div>
      <Body>{children}</Body>
    </Container>
  );
};
