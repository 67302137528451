import styled from 'styled-components';

import { pxToRem } from '@utils/format/pixelToRem';

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${pxToRem('24px')};
  padding: ${pxToRem('24px')};

  background-color: #ffffff;
  border: 1px solid #dedede;
  border-radius: ${pxToRem('8px')};

  width: 100%;

  @media (min-width: 768px) {
    width: ${pxToRem('440px')};
  }
`;
