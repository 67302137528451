import styled from 'styled-components';

import { pxToRem } from '@utils/format/pixelToRem';

export const SubHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${pxToRem('16px')};
  background-color: #fdfdfd;
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
`;

export const SubHeaderContent = styled.main`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 122.2rem;
`;

export const SubHeaderTitle = styled.h1`
  font-size: ${pxToRem('14px')};
  font-weight: 500;
  color: #656a6e;
`;
