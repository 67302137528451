import { useState } from 'react';

import { useLocation } from 'react-router-dom';
import { toast } from 'sonner';

import { Button } from '@components/Button';
import { Input } from '@components/Input';

import { useRecoverPasswordReset } from '@services/auth';

import { ErrorMessages } from '@utils/errors';

import { ApiError } from '@entities/Response';

import { PasswordStrength } from '@src/modules/password/components/passwordStrength';
import { getPasswordStrength } from '@src/modules/password/utils/getPasswordStrength';

import { FormContainer } from './styles';

export const ResetPasswordForm: React.FC = () => {
  const location = useLocation();
  const [password, setPassword] = useState('');

  const { mutate, isPending } = useRecoverPasswordReset({
    onError: (error: ApiError) => {
      toast.error(
        ErrorMessages[
          error.response?.data.errors[0].code ?? 'USER_COULD_NOT_FOUND'
        ]
      );
    },
  });

  const handleResetPassword = (password: string) => {
    const data = {
      password,
      token: location.search.split('=')[1],
    };

    mutate(data);
  };

  const passwordStrength = getPasswordStrength(password);

  return (
    <FormContainer>
      <Input type="password" label="Nova senha" placeholder="Digite aqui" />
      <Input
        type="password"
        label="Confirmar sua senha"
        placeholder="Digite aqui"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <PasswordStrength strength={passwordStrength} />
      <Button type="submit" onClick={() => handleResetPassword(password)}>
        {isPending ? 'Carregando...' : 'Enviar'}
      </Button>
    </FormContainer>
  );
};
