/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/order */
import { MainLayout } from '@layouts/main';

import { BillingCard } from '@billing/components/Card';

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  PaginationComponent,
  TableRow,
  TableFooter,
} from '@wisecare-tech/design-system-web';

import { columnsByDay } from '@billing/components/Table/columnsByDay';
import { columnsBySector } from '@billing/components/Table/columnsBySector';
import { columnsByService } from '@billing/components/Table/columnsByService';
import { columnsGeral } from '@billing/components/Table/columnsGeral';
import { queryClient } from '@libs/react-query';
import {
  listInvoiceByDay,
  listInvoiceByDetails,
  listInvoiceByOrgUnit,
  listInvoiceByService,
} from '@services/invoice/keys';
import {
  InvoiceByDayResponse,
  InvoiceByDetailsResponse,
  InvoiceByServiceResponse,
  InvoiceByOrgUnitResponse,
  OrgUnitsResponse,
} from '@services/invoice/types';
import { useMemo, useState } from 'react';
import { EmptyTable } from '@billing/components/Table/emptyState';

export const Billing: React.FC = () => {
  const [visualization, setVisualization] = useState<number>(1);

  const dataOrgUnit = queryClient.getQueryData<InvoiceByOrgUnitResponse>(
    listInvoiceByOrgUnit()
  );

  const dataService = queryClient.getQueryData<InvoiceByServiceResponse>(
    listInvoiceByService()
  );

  const dataByDay = queryClient.getQueryData<InvoiceByDayResponse>(
    listInvoiceByDay()
  );

  const dataByDetails = queryClient.getQueryData<InvoiceByDetailsResponse>(
    listInvoiceByDetails()
  );

  // useMemo

  const visualizationTable = useMemo(() => {
    const table: Record<number, any> = {
      1: dataOrgUnit?.orgUnits || [],
      2: dataService?.services || [],
      3: dataByDay?.days || [],
      4: dataByDetails?.orgs || [],
    };

    return table[visualization];
  }, [
    dataByDay?.days,
    dataByDetails?.orgs,
    dataOrgUnit?.orgUnits,
    dataService?.services,
    visualization,
  ]);

  const tableColumns = useMemo(() => {
    const columns: Record<number, any> = {
      1: columnsBySector,
      2: columnsByService,
      3: columnsByDay,
      4: columnsGeral,
    };

    return columns[visualization];
  }, [visualization]);

  const table = useReactTable({
    data: visualizationTable,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <MainLayout>
      <BillingCard
        visualization={visualization}
        setVisualization={setVisualization}
      />
      <div>
        <Table>
          <TableHeader>
            {table?.getHeaderGroups()?.map((headerGroup) => (
              <>
                {headerGroup?.headers?.map((header) => (
                  <TableHead key={header?.id}>
                    {header?.isPlaceholder
                      ? null
                      : flexRender(
                          header?.column.columnDef.header,
                          header?.getContext()
                        )}
                  </TableHead>
                ))}
              </>
            ))}
          </TableHeader>
          <TableBody style={{ backgroundColor: 'white' }}>
            {table?.getRowModel().rows?.length ? (
              table?.getRowModel()?.rows.map((row) => (
                <TableRow key={row.id}>
                  {row?.getAllCells()?.map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={tableColumns?.length}
                  className="h-24 text-center"
                >
                  <EmptyTable />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow className="hover:tw-bg-white-1">
              <TableCell colSpan={tableColumns?.length}>
                <PaginationComponent />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </MainLayout>
  );
};
