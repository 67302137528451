/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { baseApi } from '@libs/axios';

import { useCurrentUser } from '@stores/auth';

import { CookieKey, setCookie } from '@utils/cookies';

import { ApiError } from '@entities/Response';

import {
  MutationAuthResponse,
  MutationAuthVariables,
  RecoverPasswordConfirmationProps,
  RecoverPasswordProps,
} from './types';

export const useAuth = (
  options?: Partial<
    UseMutationOptions<MutationAuthResponse, ApiError, MutationAuthVariables>
  >
) => {
  return useMutation({
    mutationFn: async ({ login, password }) => {
      const { data } = await baseApi.post<MutationAuthResponse>('/auth/user', {
        login,
        password,
      });

      return data;
    },
    onSuccess(data) {
      const { setCurrentUser } = useCurrentUser.getState();
      setCookie(CookieKey.AuthToken, `Bearer ${data.access.token}`);
      setCurrentUser(data);
    },
    ...options,
  });
};

export const useRecoverPassword = (
  options?: Partial<UseMutationOptions<any, ApiError, RecoverPasswordProps>>
) => {
  return useMutation({
    mutationFn: async ({ email }) => {
      const { data } = await baseApi.post<any>('/passwords/recovery', {
        email,
      });

      return data;
    },
    ...options,
  });
};

export const useRecoverPasswordReset = (
  options?: Partial<
    UseMutationOptions<any, ApiError, RecoverPasswordConfirmationProps>
  >
) => {
  return useMutation({
    mutationFn: async ({ token, password }) => {
      const { data } = await baseApi.post<any>(
        '/passwords/recovery/confirmation',
        {
          token,
          password,
        }
      );

      return data;
    },
    ...options,
  });
};
