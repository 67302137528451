import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { Select } from '@components/Select';

import useDashboardFilter from '@hooks/useDashboardFilter';
import useFullscreen from '@hooks/useFullscreen';

import { useOrgList, useOrgUnitList } from '@services/org';

import { FilterContainer, SelectContainer, SelectLabel } from './styles';

export const DashboardFilter: React.FC = () => {
  const { data: orgs } = useOrgList();
  const { mutate, data: units } = useOrgUnitList();
  const { filter, handleFilter } = useDashboardFilter();
  const { handleFullscreen } = useFullscreen();

  return (
    <FilterContainer>
      <h3>Painel de monitoramento</h3>
      <SelectContainer>
        <SelectLabel>Organização:</SelectLabel>
        <Select
          name="org"
          onChange={(e) => {
            mutate(e.target.value);
            handleFilter(e);
          }}
          value={filter.org}
        >
          <option value="all">Todas as organizações</option>
          {orgs?.records?.map((org) => (
            <option key={org.id} value={org.shortname}>
              <p>{org.fullname}</p>
            </option>
          ))}
        </Select>
      </SelectContainer>
      <SelectContainer width="800px">
        <SelectLabel>Setor:</SelectLabel>
        <Select
          disabled={filter.org === 'all'}
          name="unit"
          onChange={handleFilter}
        >
          <option value="all">Todos os setores</option>
          {units?.records?.map((unit) => (
            <option key={unit.id} value={unit.shortname}>
              <p>{unit.fullname}</p>
            </option>
          ))}
        </Select>
      </SelectContainer>
      <SelectContainer width="1000px">
        <SelectLabel>Período:</SelectLabel>
        <Select name="period" onChange={handleFilter}>
          <option value="all">Geral</option>
          <option value="lastYear">Últimos 12 meses</option>
          <option value="lastMonth">Últimos 30 dias</option>
          <option value="today">Hoje</option>
        </Select>
        <Button variant="icon" onClick={handleFullscreen}>
          <Icon name="fullscreen" />
        </Button>
      </SelectContainer>
    </FilterContainer>
  );
};
