/* eslint-disable import/order */
import { Button } from '@components/Button';
import { Input } from '@components/Input';
import { toast } from 'sonner';
import { ErrorMessages } from '@utils/errors';
import { useRecoverPassword } from '@services/auth';

import { ApiError } from '@entities/Response';
import { FormContainer } from './styles';
import { useState } from 'react';

export const RequestLinkForm: React.FC = () => {
  const [email, setEmail] = useState('');

  const { mutate, isPending } = useRecoverPassword({
    onError: (error: ApiError) => {
      toast.error(
        ErrorMessages[
          error.response?.data.errors[0].code ?? 'USER_COULD_NOT_FOUND'
        ]
      );
    },
  });

  const handleRequestLink = (data: { email: string }) => {
    mutate(data);
  };

  return (
    <FormContainer>
      <Input
        type="email"
        label="E-mail"
        placeholder="E-mail"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button type="submit" onClick={() => handleRequestLink({ email })}>
        {isPending ? 'Carregando...' : 'Enviar'}
      </Button>
    </FormContainer>
  );
};
