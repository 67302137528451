import styled from 'styled-components';

import { pxToRem } from '@utils/format/pixelToRem';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #fbfbfb;
`;

export const Body = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  gap: ${pxToRem('24px')};
  padding-top: ${pxToRem('24px')};
`;
