import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  /* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } */

  * {
    margin: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  html {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #1B4A7A;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #646464;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:active {
    background: #444444;
    border-radius: 10px;
  }
  

  body {
    height: 100vh;
    width: 100%;
    top: 0px;
    left: 0px;

    /* background: var(--color_background); */
    background-position: bottom !important;
    background-repeat: no-repeat;
    background-attachment: fixed !important;
    opacity: 1;
    -webkit-font-smoothing: antialiased;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      margin: 0;
    }

    /* input:-webkit-autofill,
    input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }
    input[data-autocompleted] {
        background-color: transparent !important;
    } */
  }

  main {
    width: 100%;
    padding: 0 7.2rem;
  }

  #root {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;

    .App {
      width: 100dvw;
      height: 100dvh;
    }
  }
`;
