/* eslint-disable @typescript-eslint/no-unused-vars */
import { ColumnDef } from '@tanstack/react-table';
import { SortButton, Typography } from '@wisecare-tech/design-system-web';

import { OrgUnitsResponse } from '@services/invoice/types';

import { formatCurrency } from '@utils/format/currency';

import { Information } from './styles';

export const columnsBySector: ColumnDef<OrgUnitsResponse>[] = [
  {
    accessorKey: 'fullname',
    header: ({ column }) => <SortButton column={column}>Setor</SortButton>,
    cell: ({ row }) => {
      const fullname = row.getValue('fullname') as OrgUnitsResponse['fullname'];

      // const value = row.original;

      return (
        <Information>
          <Typography variant="b4_14regular">{fullname}</Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'short',
    header: ({ column }) => {
      return <SortButton column={column}>Sigla</SortButton>;
    },
    cell: ({ row }) => {
      const short = row.getValue('short') as OrgUnitsResponse['short'];

      return <Typography variant="b4_14regular">{short}</Typography>;
    },
  },
  {
    accessorKey: 'total',
    header: ({ column }) => <SortButton column={column}>Consumo</SortButton>,
    cell: ({ row }) => {
      const value = row.original;

      return (
        <Information>
          <Typography variant="b4_14regular">
            {formatCurrency(value.total, 'R$/h')}
          </Typography>
        </Information>
      );
    },
  },
];
