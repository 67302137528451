import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { baseApi } from '@libs/axios';

import parseData from '@utils/parseData';

import { ApiError, ApiResponse } from '@entities/Response';

import { orgUnit } from './keys';
import { OrgUnitsListParams, OrgUnitsListResponse } from './types';

export const useOrgUnitsList = (params: {
  filters?: OrgUnitsListParams;
  options?: UseQueryOptions<OrgUnitsListResponse, ApiError>;
}) => {
  return useQuery({
    queryKey: orgUnit(),
    refetchInterval: false,
    retry: 3,
    staleTime: Infinity,
    queryFn: () =>
      baseApi
        .post<ApiResponse<OrgUnitsListResponse>>(
          `/orgUnits/list`,
          params?.filters
        )
        .then(parseData),
    ...params?.options,
  });
};

export const useFetchOrgUnitsList = () => {
  return useMutation<OrgUnitsListResponse, ApiError, OrgUnitsListParams>({
    mutationFn: (filters: OrgUnitsListParams) =>
      baseApi
        .post<ApiResponse<OrgUnitsListResponse>>(`/orgUnits/list`, filters)
        .then(parseData),
  });
};
